import * as $ from 'jquery'
import { each } from 'jquery'
import Swiper from 'swiper/bundle'

(function ($) {

	var $nWindowWidth = $(window).width(),
		$nWindowHeight = $(window).height(),
		$nClientWidth = Math.ceil($("body").prop("clientWidth")),
		$nClientHeight = Math.ceil($("body").prop("clientHeight")),
		$objFeatureBannerSwiper,
		$objBillboardBannerSwiper,
		$objFullscreenBannerSwiper,
		$objFullscreenBannerMobileSwiper,
		$objFeatureShop1Swiper,
		$objFeatureShop2Swiper,
		$objBillboardShop1Swiper,
		$objBillboardShop2Swiper,
		$objFullscreenShop1Swiper,
		$objFullscreenShop2Swiper

	$(document).ready(function () {

		/* FEATURE START */

		$(".feature-banner__list-item .list-item__rail-nav .rail-nav__item").hover(
			function () {
				var nNavPage = parseInt($(this).attr('data-nav'))
				$('.feature-banner__list-item .list-item__rail-picture[data-nav="' + nNavPage + '"]').addClass('--hover')
			},
			function () {
				$('.feature-banner__list-item .list-item__rail-picture[data-nav]').removeClass('--hover')
			}
		)

		$('.feature-banner__list-item .list-item__about-buttons .about-buttons__more').on('click', function () {
			$(this).closest('.feature-banner__list-item').find('.list-item__content').addClass('--open')
		})

		$('.feature-banner__list-item .list-item__content-close').on('click', function () {
			$(this).parent().removeClass('--open')
		})

		$('.feature-banner:not(.--feature-2) .feature-banner__list-item .list-item__tags-item span.idle, .feature-banner__list-item .list-item__bag').on('click', function () {
			var shopId = $(this).attr('data-shop')
			$('.feature-banner__shop[data-shop="' + shopId + '"]').addClass('--open')
		})

		$('.feature-banner__list-item .list-item__bag').on('click', function () {
			var shopId = parseInt($(this).attr('data-shop'))
			$('.feature-banner__shop[data-shop="' + shopId + '"]').addClass('--open')
		})

		$('.feature-banner.--feature-2 .feature-banner__list-item .list-item__tags-item span.idle').on('click', function () {

			var shopId = parseInt($(this).attr('data-shop')),
				slideId = parseInt($(this).attr('data-slide'))

			if (shopId == 1)
				$objFeatureShop1Swiper.slideTo(slideId, 0)
			else if (shopId == 2)
				$objFeatureShop2Swiper.slideTo(slideId, 0)


			$('.feature-banner__shop[data-shop="' + shopId + '"]').addClass('--open')

		})

		$('.feature-banner__shop-close').on('click', function () {
			$(this).parent().removeClass('--open')
		})

		if ($('.feature-banner-swiper').length > 0) {

			var nInitialSlide = $('.feature-banner-swiper').attr(' data-initial-slide') ?? 0,
				$objFeatureBannerSwiper = new Swiper($('.feature-banner-swiper').get(0), {
					slidesPerView: 1,
					spaceBetween: 0,
					loop: true,
					initialSlide: nInitialSlide,
					speed: 1000,
					autoplay: {
						delay: 15000,
						disableOnInteraction: true
					}
				})

			$('.feature-banner__list-item .list-item__rail-nav .rail-nav__item').on('click', function (e) {
				$objFeatureBannerSwiper.slideToLoop(parseInt($(this).attr('data-nav')) - 1, 1000)
			})

			$('.feature-banner__list-item .list-item__about-buttons .about-buttons__next').on('click', function (e) {
				$objFeatureBannerSwiper.slideNext(1000)
			})

			$('.feature-banner__list-item .list-item__about-buttons .about-buttons__prev').on('click', function (e) {
				$objFeatureBannerSwiper.slidePrev(1000)
			})

			$objFeatureBannerSwiper.on('slideChangeTransitionEnd', function () {
				$('.feature-banner__list-item .list-item__content.--open').removeClass('--open')
			})

		}

		if ($('.feature-banner__shop[data-shop="1"] .shop-slider__list.swiper').length > 0) {

			$objFeatureShop1Swiper = new Swiper($('.feature-banner__shop[data-shop="1"] .shop-slider__list.swiper').get(0), {
				slidesPerView: 1,
				spaceBetween: 0,
				loop: false,
				speed: 1000
			})

			$objFeatureShop1Swiper.on('slideChangeTransitionStart', function (e) {
				$(e.slides[$objFeatureShop1Swiper.activeIndex]).closest('.feature-banner__shop-slider').find('.shop-slider__title-text').html($(e.slides[$objFeatureShop1Swiper.activeIndex]).attr('data-title'))
			})

			$('.feature-banner__shop[data-shop="1"] .feature-banner__shop-slider .shop-slider__title-next').on('click', function (e) {
				if ($objFeatureShop1Swiper.realIndex == 1)
					$objFeatureShop1Swiper.slidePrev(1000)
				else
					$objFeatureShop1Swiper.slideNext(1000)
			})

			$('.feature-banner__shop[data-shop="1"] .feature-banner__shop-slider .shop-slider__title-prev').on('click', function (e) {
				if ($objFeatureShop1Swiper.realIndex == 0)
					$objFeatureShop1Swiper.slideNext(1000)
				else
					$objFeatureShop1Swiper.slidePrev(1000)
			})

		}

		if ($('.feature-banner__shop[data-shop="2"] .shop-slider__list.swiper').length > 0) {

			$objFeatureShop2Swiper = new Swiper($('.feature-banner__shop[data-shop="2"] .shop-slider__list.swiper').get(0), {
				slidesPerView: 1,
				spaceBetween: 0,
				loop: false,
				speed: 1000
			})

			$objFeatureShop2Swiper.on('slideChangeTransitionStart', function (e) {
				$(e.slides[$objFeatureShop2Swiper.activeIndex]).closest('.feature-banner__shop-slider').find('.shop-slider__title-text').html($(e.slides[$objFeatureShop2Swiper.activeIndex]).attr('data-title'))
			})

			$('.feature-banner__shop[data-shop="2"] .feature-banner__shop-slider .shop-slider__title-next').on('click', function (e) {
				if ($objFeatureShop2Swiper.realIndex == 1)
					$objFeatureShop2Swiper.slidePrev(1000)
				else
					$objFeatureShop2Swiper.slideNext(1000)
			})

			$('.feature-banner__shop[data-shop="2"] .feature-banner__shop-slider .shop-slider__title-prev').on('click', function (e) {
				if ($objFeatureShop2Swiper.realIndex == 0)
					$objFeatureShop2Swiper.slideNext(1000)
				else
					$objFeatureShop2Swiper.slidePrev(1000)
			})

		}

		$('.feature-banner-mobile__list-item:eq(0) .list-item__look, .feature-banner-mobile__list-item:eq(0) .list-item__content-tags .content-tags__item, .feature-banner-mobile__list-item:eq(1) .list-item__tags-item, .feature-banner-mobile__list-item:eq(2) .list-item__tags-item, .feature-banner-mobile__list-item [data-shop-open]').on('click', function () {
			var nShopId = $(this).closest('.feature-banner-mobile__list-item').attr('data-shop')
			$('.feature-banner-mobile__viewport.--shop[data-shop="' + nShopId + '"]').addClass('--open')
		})

		$('.feature-banner-mobile__close').on('click', function () {
			$(this).closest('.feature-banner-mobile__viewport.--shop').removeClass('--open')
		})

		$('.feature-banner-mobile__list-item [data-read-click]').on('click', function () {
			var nShopId = $(this).closest('.feature-banner-mobile__list-item').attr('data-shop')
			$('.feature-banner-mobile__viewport.--about[data-about="' + nShopId + '"]').addClass('--open')
		})

		$('.feature-banner-mobile__close.--about').on('click', function () {
			$(this).closest('.feature-banner-mobile__viewport.--about').removeClass('--open')
		})

		$('.feature-banner-mobile__list-item:eq(1) .list-item__buttons-arrows .buttons-arrows__next').on('click', function (e) {
			$('.feature-banner-mobile__viewport').stop().animate({
				scrollLeft: 1300
			}, 500);
			e.preventDefault();
		})

		$('.feature-banner-mobile__list-item:eq(1) .list-item__buttons-arrows .buttons-arrows__prev').on('click', function (e) {
			$('.feature-banner-mobile__viewport').stop().animate({
				scrollLeft: 0
			}, 500);
			e.preventDefault();
		})

		$('.feature-banner-mobile__list-item:eq(0) .list-item__content-tags, .feature-banner-mobile__list-item:eq(1) .list-item__tags, .feature-banner-mobile__list-item:eq(2) .list-item__tags').on('click', function () {
			$(this).addClass('--open')
		})

		/* FEATURE END */

		/* BILLBOARD START */

		$(".billboard-banner__list-item .list-item__rail-nav .rail-nav__item").hover(
			function () {
				var nNavPage = parseInt($(this).attr('data-nav'))
				$('.billboard-banner__list-item .list-item__rail-picture[data-nav="' + nNavPage + '"]').addClass('--hover')
			},
			function () {
				$('.billboard-banner__list-item .list-item__rail-picture[data-nav]').removeClass('--hover')
			}
		)

		$(".billboard-banner__list-item .list-item__buttons-more").on('click', function () {
			$(this).closest('.billboard-banner__list-item').find('.list-item__content').addClass('--open')
		})

		$('.billboard-banner__list-item .list-item__content-close').on('click', function () {
			$(this).parent().removeClass('--open')
		})

		$(".billboard-banner:not(.--billboard-2) .billboard-banner__list-item .list-item__tags-item span, .billboard-banner__list-item .list-item__shop-icon").on('click', function () {
			var shopId = $(this).attr('data-shop-id')
			$('.billboard-banner__shop[data-shop="' + shopId + '"]').addClass('--open')
		})

		$(".billboard-banner__shop-close").on('click', function () {
			$(this).parent().removeClass('--open')
		})

		$('.billboard-banner.--billboard-2 .billboard-banner__list-item .list-item__tags-item span.idle').on('click', function () {

			var shopId = parseInt($(this).attr('data-shop-id')),
				slideId = parseInt($(this).attr('data-slide'))

			if (shopId == 1)
				$objBillboardShop1Swiper.slideTo(slideId, 0)
			else if (shopId == 2)
				$objBillboardShop2Swiper.slideTo(slideId, 0)


			$('.billboard-banner__shop[data-shop="' + shopId + '"]').addClass('--open')

		})

		if ($('.billboard-banner-swiper').length > 0) {
			var nInitialSlide = $('.billboard-banner-swiper').attr(' data-initial-slide') ?? 0,
				$objBillboardBannerSwiper = new Swiper($('.billboard-banner-swiper').get(0), {
					slidesPerView: 1,
					spaceBetween: 0,
					loop: true,
					initialSlide: nInitialSlide,
					speed: 1000,
					autoplay: {
						delay: 15000,
						disableOnInteraction: true
					}
				})

			$('.billboard-banner__list-item .list-item__rail-nav .rail-nav__item').on('click', function (e) {
				$objBillboardBannerSwiper.slideToLoop(parseInt($(this).attr('data-nav')) - 1, 1000)
			})

			$('.billboard-banner__list-item .list-item__buttons-next').on('click', function (e) {
				$objBillboardBannerSwiper.slideNext(1000)
			})

			$('.billboard-banner__list-item .list-item__buttons-prev').on('click', function (e) {
				$objBillboardBannerSwiper.slidePrev(1000)
			})

			$objBillboardBannerSwiper.on('slideChangeTransitionEnd', function () {
				$('.billboard-banner__list-item .list-item__content.--open').removeClass('--open')
			})

		}

		if ($('.billboard-banner__shop[data-shop="1"] .shop-slider__list.swiper').length > 0) {

			$objBillboardShop1Swiper = new Swiper($('.billboard-banner__shop[data-shop="1"] .shop-slider__list.swiper').get(0), {
				slidesPerView: 1,
				spaceBetween: 0,
				loop: false,
				speed: 1000
			})

			$objBillboardShop1Swiper.on('slideChangeTransitionStart', function (e) {
				$(e.slides[$objBillboardShop1Swiper.activeIndex]).closest('.billboard-banner__shop-slider').find('.shop-slider__title-text').html($(e.slides[$objBillboardShop1Swiper.activeIndex]).attr('data-title'))
			})

			$('.billboard-banner__shop[data-shop="1"] .billboard-banner__shop-slider .shop-slider__title-next').on('click', function (e) {
				if ($objBillboardShop1Swiper.realIndex == 1)
					$objBillboardShop1Swiper.slidePrev(1000)
				else
					$objBillboardShop1Swiper.slideNext(1000)
			})

			$('.billboard-banner__shop[data-shop="1"] .billboard-banner__shop-slider .shop-slider__title-prev').on('click', function (e) {
				if ($objBillboardShop1Swiper.realIndex == 0)
					$objBillboardShop1Swiper.slideNext(1000)
				else
					$objBillboardShop1Swiper.slidePrev(1000)
			})

		}

		if ($('.billboard-banner__shop[data-shop="2"] .shop-slider__list.swiper').length > 0) {

			$objBillboardShop2Swiper = new Swiper($('.billboard-banner__shop[data-shop="2"] .shop-slider__list.swiper').get(0), {
				slidesPerView: 1,
				spaceBetween: 0,
				loop: false,
				speed: 1000
			})

			$objBillboardShop2Swiper.on('slideChangeTransitionStart', function (e) {
				$(e.slides[$objBillboardShop2Swiper.activeIndex]).closest('.billboard-banner__shop-slider').find('.shop-slider__title-text').html($(e.slides[$objBillboardShop2Swiper.activeIndex]).attr('data-title'))
			})

			$('.billboard-banner__shop[data-shop="2"] .billboard-banner__shop-slider .shop-slider__title-next').on('click', function (e) {
				if ($objBillboardShop2Swiper.realIndex == 1)
					$objBillboardShop2Swiper.slidePrev(1000)
				else
					$objBillboardShop2Swiper.slideNext(1000)
			})

			$('.billboard-banner__shop[data-shop="2"] .billboard-banner__shop-slider .shop-slider__title-prev').on('click', function (e) {
				if ($objBillboardShop2Swiper.realIndex == 0)
					$objBillboardShop2Swiper.slideNext(1000)
				else
					$objBillboardShop2Swiper.slidePrev(1000)
			})

		}

		$(".billboard-banner-mobile__list-item [data-read-id]").on('click', function () {
			var nReadId = $(this).attr('data-read-id')
			$('.billboard-banner-mobile__viewport.--about[data-about="' + nReadId + '"]').addClass('--open')
		})

		$('.billboard-banner-mobile__close.--about').on('click', function () {
			$(this).closest('.billboard-banner-mobile__viewport.--about').removeClass('--open')
		})

		$('.billboard-banner-mobile__list-item .list-item__tags').on('click', function () {
			$(this).addClass('--open')
		})

		$('.billboard-banner-mobile__list-item [data-shop-id]').on('click', function () {
			var nShopId = $(this).attr('data-shop-id')
			$('.billboard-banner-mobile__viewport.--shop[data-shop="' + nShopId + '"]').addClass('--open')
		})

		$('.billboard-banner-mobile__close').on('click', function () {
			$(this).closest('.billboard-banner-mobile__viewport.--shop').removeClass('--open')
		})

		/* BILLBOARD END */

		/* FULLSCREEN START */

		$(".fullscreen-banner__list-item .list-item__rail-nav .rail-nav__item").hover(
			function () {
				var nNavPage = parseInt($(this).attr('data-nav'))
				$('.fullscreen-banner__list-item .list-item__rail-picture[data-nav="' + nNavPage + '"]').addClass('--hover')
			},
			function () {
				$('.fullscreen-banner__list-item .list-item__rail-picture[data-nav]').removeClass('--hover')
			}
		)

		$('.fullscreen-banner:not(.--fullscreen-2) .fullscreen-banner__list-item .list-item__tags-item span.idle').on('click', function () {
			var shopId = $(this).attr('data-shop')
			$('.fullscreen-banner__shop[data-shop="' + shopId + '"]').addClass('--open')
		})

		$('.fullscreen-banner.--fullscreen-2 .fullscreen-banner__list-item .list-item__tags-item span.idle').on('click', function () {

			var shopId = parseInt($(this).attr('data-shop')),
				slideId = parseInt($(this).attr('data-slide'))

			if (shopId == 1)
				$objFullscreenShop1Swiper.slideTo(slideId, 0)
			else if (shopId == 2)
				$objFullscreenShop2Swiper.slideTo(slideId, 0)


			$('.fullscreen-banner__shop[data-shop="' + shopId + '"]').addClass('--open')

		})

		$('.fullscreen-banner__list-item .list-item__content-buttons .content-buttons__more').on('click', function () {
			$(this).closest('.fullscreen-banner__list-item').find('.list-item__about').addClass('--open')
		})

		$('.fullscreen-banner__list-item .list-item__about-close').on('click', function () {
			$(this).parent().removeClass('--open')
		})

		if ($('.fullscreen-banner-swiper').length > 0) {
			var nInitialSlide = $('.fullscreen-banner-swiper').attr(' data-initial-slide') ?? 0,
				$objFullscreenBannerSwiper = new Swiper($('.fullscreen-banner-swiper').get(0), {
					slidesPerView: 1,
					spaceBetween: 0,
					loop: true,
					initialSlide: nInitialSlide,
					speed: 1000,
					/*autoplay: {
						delay: 15000,
						disableOnInteraction: true
					}*/
				})

			$('.fullscreen-banner__list-item .list-item__rail-nav .rail-nav__item').on('click', function (e) {
				$objFullscreenBannerSwiper.slideToLoop(parseInt($(this).attr('data-nav')) - 1, 1000)
			})

			$('.fullscreen-banner__list-item .list-item__content-buttons .content-buttons__next').on('click', function (e) {
				$objFullscreenBannerSwiper.slideNext(1000)
			})

			$('.fullscreen-banner__list-item .list-item__content-buttons .content-buttons__prev').on('click', function (e) {
				$objFullscreenBannerSwiper.slidePrev(1000)
			})

			$objFullscreenBannerSwiper.on('slideChangeTransitionEnd', function () {
				$('.fullscreen-banner__list-item .list-item__about.--open').removeClass('--open')
			})

		}

		if ($('.fullscreen-banner__shop[data-shop="1"] .shop-slider__list.swiper').length > 0) {

			$objFullscreenShop1Swiper = new Swiper($('.fullscreen-banner__shop[data-shop="1"] .shop-slider__list.swiper').get(0), {
				slidesPerView: 1,
				spaceBetween: 0,
				loop: false,
				speed: 1000
			})

			$objFullscreenShop1Swiper.on('slideChangeTransitionStart', function (e) {
				$(e.slides[$objFullscreenShop1Swiper.activeIndex]).closest('.fullscreen-banner__shop-slider').find('.shop-slider__title-text').html($(e.slides[$objFullscreenShop1Swiper.activeIndex]).attr('data-title'))
			})

			$('.fullscreen-banner__shop[data-shop="1"] .fullscreen-banner__shop-slider .shop-slider__title-next').on('click', function (e) {
				if ($objFullscreenShop1Swiper.realIndex == 1)
					$objFullscreenShop1Swiper.slidePrev(1000)
				else
					$objFullscreenShop1Swiper.slideNext(1000)
			})

			$('.fullscreen-banner__shop[data-shop="1"] .fullscreen-banner__shop-slider .shop-slider__title-prev').on('click', function (e) {
				if ($objFullscreenShop1Swiper.realIndex == 0)
					$objFullscreenShop1Swiper.slideNext(1000)
				else
					$objFullscreenShop1Swiper.slidePrev(1000)
			})

		}

		if ($('.fullscreen-banner__shop[data-shop="2"] .shop-slider__list.swiper').length > 0) {

			$objFullscreenShop2Swiper = new Swiper($('.fullscreen-banner__shop[data-shop="2"] .shop-slider__list.swiper').get(0), {
				slidesPerView: 1,
				spaceBetween: 0,
				loop: false,
				speed: 1000
			})

			$objFullscreenShop2Swiper.on('slideChangeTransitionStart', function (e) {
				$(e.slides[$objFullscreenShop2Swiper.activeIndex]).closest('.fullscreen-banner__shop-slider').find('.shop-slider__title-text').html($(e.slides[$objFullscreenShop2Swiper.activeIndex]).attr('data-title'))
			})

			$('.fullscreen-banner__shop[data-shop="2"] .fullscreen-banner__shop-slider .shop-slider__title-next').on('click', function (e) {
				if ($objFullscreenShop2Swiper.realIndex == 1)
					$objFullscreenShop2Swiper.slidePrev(1000)
				else
					$objFullscreenShop2Swiper.slideNext(1000)
			})

			$('.fullscreen-banner__shop[data-shop="2"] .fullscreen-banner__shop-slider .shop-slider__title-prev').on('click', function (e) {
				if ($objFullscreenShop2Swiper.realIndex == 0)
					$objFullscreenShop2Swiper.slideNext(1000)
				else
					$objFullscreenShop2Swiper.slidePrev(1000)
			})

		}

		$('.fullscreen-banner__list-item [data-shop-id]').on('click', function () {
			var shopId = $(this).attr('data-shop-id')
			$('.fullscreen-banner__shop[data-shop="' + shopId + '"]').addClass('--open')
		})

		$('.fullscreen-banner__shop-close').on('click', function () {
			$(this).parent().removeClass('--open')
		})

		if ($('.fullscreen-banner-mobile-swiper').length > 0) {

			var nInitialSlide = $('.fullscreen-banner-mobile-swiper').attr('data-initial-slide') ?? 0,
				$objFullscreenBannerMobileSwiper = new Swiper($('.fullscreen-banner-mobile-swiper').get(0), {
					slidesPerView: 1,
					spaceBetween: 0,
					loop: true,
					initialSlide: nInitialSlide,
					speed: 500,
					/*autoplay: {
						delay: 15000,
						disableOnInteraction: true
					}*/
				})

			$objFullscreenBannerMobileSwiper.on('slideChangeTransitionEnd', function () {
				$('.fullscreen-banner-mobile__list-item.--open-about').removeClass('--open-about')
			})

		}

		$('.fullscreen-banner-mobile__list-item [data-shop-id]').on('click', function () {
			var nShopId = $(this).attr('data-shop-id')
			$('.fullscreen-banner-mobile__viewport.--shop[data-shop="' + nShopId + '"]').addClass('--open')
		})

		$('.fullscreen-banner-mobile__close').on('click', function () {
			$(this).closest('.fullscreen-banner-mobile__viewport.--shop').removeClass('--open')
		})

		$('.fullscreen-banner-mobile__list-item .list-item__tags').on('click', function () {
			$(this).addClass('--open')
		})

		$('.fullscreen-banner-mobile__list-item .list-item__header-read').on('click', function () {
			$(this).closest('.fullscreen-banner-mobile__list-item').toggleClass('--open-about')
		})

		$('.fullscreen-banner-mobile__list-item .list-item__tags-item span.idle').on('click', function () {
			if ($(this).closest('.list-item__tags').hasClass('--open')) {
				var shopId = $(this).attr('data-shop-id')
				$('.fullscreen-banner-mobile__shop[data-shop="' + shopId + '"]').addClass('--open')
			}
		})

		/* FULLSCREEN END */



	})

	$(window).on('load', function () {

		$nWindowWidth = $(window).width()
		$nWindowHeight = $(window).height()
		$nClientWidth = Math.ceil($("body").prop("clientWidth"))
		$nClientHeight = Math.ceil($("body").prop("clientHeight"))

	})

	$(window).on('resize', function (event) {

		$nWindowWidth = $(window).width()
		$nWindowHeight = $(window).height()
		$nClientWidth = Math.ceil($("body").prop("clientWidth"))
		$nClientHeight = Math.ceil($("body").prop("clientHeight"))

	})

	$(window).on('scroll', function (event) {

	})

})(jQuery)
